export default {
  async update (Vue, formData) {
    let apiCalls = []
    for (const fieldname in formData) {
      let values = {}
      if (fieldname.startsWith('contratado_')) {
        values.idservicio_contratado = parseInt(fieldname.substring('contratado_'.length))
        values.contratado = formData[fieldname]
      }
      if (values.idservicio_contratado) {
        apiCalls.push({
          name: 'servicioContratadoUpdate',
          method: 'servicioContratado.update',
          params: { values },
        })
      }
    }
    await Vue.$api.batchCall(apiCalls)
  },
}
