import { APIFilter } from '@/utils/api'

export default {
  async selectFormData (Vue, id) {
    const apiFilter = new APIFilter()
      .addExact('idcontrato', id)
      .addGT('estado', 0)
    const sorter = [
      { field: 'mtservicio_contratado_desc', asc: true },
      { field: 'mtservicio_contratado_orden', asc: true },
    ]
    const resp = await Vue.$api.call('servicioContratado.select', { filter: apiFilter, sorter: sorter})
    return resp.data.result.dataset
  },
}
