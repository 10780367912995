<template>
  <b10-base>
    <v-list
      dense
    >
      <template v-for="groupoServicio in serviciosContratados">
        <b10-subheader
          :key="groupoServicio.idmtservicioContratado"
          :label="groupoServicio.groupName"
        />
        <template
          v-for="servicio in groupoServicio.servicios"
        >
          <v-checkbox
            :key="servicio.idservicio_contratado"
            v-model="form[servicio.fieldnameContratado]"
            :label="servicio.title"
          />
        </template>
      </template>
    </v-list>
  </b10-base>
</template>


<script>
import formMixin from '@/mixins/formMixin'
import Data from './ServicioContratadoEditFormData'

export default {
  mixins: [formMixin],
  data () {
    return {
      form: {},
      serviciosContratados: [],
    }
  },
  async created () {
    await this.loadForm()
  },
  methods: {
    async loadFormData (id) {
      const formData = await Data.selectFormData(this, id)
      let lastLinea = {
        idservicioContratado: -1,
      }
      let lastGrupo = {
        idmtservicioContratado: -1,
      }
      for (const servicioContratado of formData) {
        if (servicioContratado.idmtservicio_contratado !== lastGrupo.idmtservicioContratado){
          lastGrupo = {
            idmtservicioContratado: servicioContratado.idmtservicio_contratado,
            groupName: servicioContratado.mtservicio_contratado_desc,
            servicios: []
          }
          this.serviciosContratados.push(lastGrupo)
        }
        if (servicioContratado.idservicioContratado !== lastLinea.idservicioContratado) {
          const fieldnameContratado = `contratado_${servicioContratado.idservicio_contratado}`
          lastLinea = {
            idservicioContratado: servicioContratado.idservicio_contratado,
            title: servicioContratado.tservicio_contratado_desc,
            contratado: servicioContratado.contratado,
            fieldnameContratado: fieldnameContratado,
          }
          lastGrupo.servicios.push(lastLinea)
          this.$set(this.form, fieldnameContratado, servicioContratado.contratado)
        }
      }
    }
  },
}

</script>